import {
  ADD_NOTIFY_ABOUT_10000_RECORDS,
  APPROVE_DUPLICATE,
  APPROVE_DUPLICATE_FAIL,
  APPROVE_DUPLICATE_SUCCESS,
  APPROVE_ENRICHMENT,
  APPROVE_ENRICHMENT_FAIL,
  APPROVE_ENRICHMENT_SUCCESS,
  CLEAR_REDUX_PEOPLE_STATE,
  CREATE_CONTACT,
  CREATE_CONTACT_FAIL,
  CREATE_CONTACT_SUCCESS,
  DELETE_CONTACT,
  DELETE_CONTACT_FAIL,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACTS,
  DELETE_CONTACTS_FAIL,
  DELETE_CONTACTS_SUCCESS,
  EDIT_CONTACT,
  EDIT_CONTACT_FAIL,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACTS,
  EDIT_CONTACTS_FAIL,
  EDIT_CONTACTS_SUCCESS,
  EXPORT_CONTACTS,
  EXPORT_CONTACTS_FAIL,
  EXPORT_CONTACTS_SUCCESS,
  FETCH_DUPLICATES,
  FETCH_DUPLICATES_COUNT,
  FETCH_DUPLICATES_FAIL,
  FETCH_DUPLICATES_SUCCESS,
  FETCH_ENRICHMENT_COUNT,
  FETCH_ENRICHMENT_COUNT_FAIL,
  FETCH_ENRICHMENT_COUNT_SUCCESS,
  FETCH_ENRICHMENTS,
  FETCH_ENRICHMENTS_FAIL,
  FETCH_ENRICHMENTS_SUCCESS,
  FETCH_PEOPLE,
  FETCH_PEOPLE_FAIL,
  FETCH_PEOPLE_FROM_ALL_PAGES,
  FETCH_PEOPLE_FROM_ALL_PAGES_FAIL,
  FETCH_PEOPLE_FROM_ALL_PAGES_SUCCESS,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PERSON,
  FETCH_PERSON_FAIL,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSONS_EVENTS,
  FETCH_PERSONS_EVENTS_SUCCESS,
  FETCH_PERSONS_INTERACTIONS,
  FETCH_PERSONS_INTERACTIONS_SUCCESS,
  FETCH_PERSONS_NOTES,
  FETCH_PERSONS_NOTES_SUCCESS,
  FETCH_PERSONS_REMINDERS,
  FETCH_PERSONS_REMINDERS_SUCCESS,
  FETCH_SELECTED_PEOPLE,
  FETCH_SELECTED_PEOPLE_FAIL,
  FETCH_SELECTED_PEOPLE_SUCCESS,
  IGNORE_DUPLICATE,
  IGNORE_DUPLICATE_FAIL,
  IGNORE_DUPLICATE_SUCCESS,
  IGNORE_ENRICHMENT,
  IGNORE_ENRICHMENT_FAIL,
  IGNORE_ENRICHMENT_SUCCESS,
  MERGE_CONTACTS,
  MERGE_CONTACTS_FAIL,
  MERGE_CONTACTS_SUCCESS,
  SET_FETCHING_STATE,
  SET_INITIAL_REDUX_PEOPLE_STATE,
  SHOW_PEOPLE_BY_IDS,
  SHOW_PEOPLE_BY_IDS_FAIL,
  SHOW_PEOPLE_BY_IDS_SUCCESS,
  UNARCHIVE_CONTACT,
  UNARCHIVE_CONTACT_FAIL,
  UNARCHIVE_CONTACT_SUCCESS,
  UPDATE_CONTACT_IMAGE,
  UPDATE_CONTACT_IMAGE_FAIL,
  UPDATE_CONTACT_IMAGE_SUCCESS,
  UPDATE_ENABLED_COLUMNS,
  UPDATE_ENABLED_COLUMNS_FAIL,
  UPDATE_ENABLED_COLUMNS_SUCCESS,
  UPDATE_PERSON_SPHERES,
  UPDATE_PERSON_SPHERES_FAIL,
  UPDATE_PERSON_SPHERES_SUCCESS,
  CLONE_TEAM_SHARED_PERSON_TO_MY_DB,
  CLONE_TEAM_SHARED_PERSON_TO_MY_DB_SUCCESS,
  CLONE_TEAM_SHARED_PERSON_TO_MY_DB_FAIL,
} from "./People.types"

export const fetchPeople = (
  page,
  per_page,
  query,
  filters,
  sortField,
  sortOrder,
  selectedPeople
) => {
  return {
    type: FETCH_PEOPLE,
    page: page || 1,
    per_page: per_page,
    query: query || null,
    sortField: sortField,
    sortOrder: sortOrder || "desc",
    filters: filters || null,
    selectedPeople: selectedPeople || [],
  }
}

export const fetchPeopleSuccess = (
  people,
  total_entries,
  enabled_columns,
  clearStore = false
) => {
  return {
    type: FETCH_PEOPLE_SUCCESS,
    people: people,
    total_entries: total_entries,
    enabled_columns: enabled_columns,
    clearStore,
  }
}

export const fetchPeopleFail = (error) => {
  return {
    type: FETCH_PEOPLE_FAIL,
    error: error,
  }
}

export const fetchPeopleFromAllPages = (query, filters) => {
  return {
    type: FETCH_PEOPLE_FROM_ALL_PAGES,
    query: query || null,
    filters: filters || null,
  }
}

export const addNotifyAbout1000Records = () => {
  return {
    type: ADD_NOTIFY_ABOUT_10000_RECORDS,
  }
}

export const fetchPeopleFromAllPagesSuccess = () => {
  return {
    type: FETCH_PEOPLE_FROM_ALL_PAGES_SUCCESS,
  }
}

export const fetchPeopleFromAllPagesFail = (error) => {
  return {
    type: FETCH_PEOPLE_FROM_ALL_PAGES_FAIL,
    error: error,
  }
}

export const fetchPersonsInteractions = (
  person_id,
  page,
  per_page = 3,
  page_token
) => {
  return {
    type: FETCH_PERSONS_INTERACTIONS,
    person_id: person_id,
    page: page,
    per_page: per_page,
    page_token: page_token,
  }
}

export const fetchPersonsInteractionsSuccess = (
  person_id,
  interactions,
  total_entries,
  page_token
) => {
  return {
    type: FETCH_PERSONS_INTERACTIONS_SUCCESS,
    person_id: person_id,
    interactions: interactions,
    total_entries: total_entries,
    page_token: page_token,
  }
}

export const fetchPersonsEvents = (person_id, page, per_page = 3) => {
  return {
    type: FETCH_PERSONS_EVENTS,
    person_id: person_id,
    page: page,
    per_page: per_page,
  }
}

export const fetchPersonsEventsSuccess = (person_id, events, total_entries) => {
  return {
    type: FETCH_PERSONS_EVENTS_SUCCESS,
    person_id: person_id,
    events: events,
    total_entries: total_entries,
  }
}

export const fetchPersonsNotes = (person_id, page, per_page = 3) => {
  return {
    type: FETCH_PERSONS_NOTES,
    person_id: person_id,
    page: page,
    per_page: per_page,
  }
}

export const fetchPersonsNotesSuccess = (person_id, notes, total_entries) => {
  return {
    type: FETCH_PERSONS_NOTES_SUCCESS,
    person_id: person_id,
    notes: notes,
    total_entries: total_entries,
  }
}

export const fetchPersonsReminders = (person_id, page, per_page = 3) => {
  return {
    type: FETCH_PERSONS_REMINDERS,
    person_id: person_id,
    page: page,
    per_page: per_page,
  }
}

export const fetchPersonsRemindersSuccess = (
  person_id,
  reminders,
  total_entries
) => {
  return {
    type: FETCH_PERSONS_REMINDERS_SUCCESS,
    person_id: person_id,
    reminders: reminders,
    total_entries: total_entries,
  }
}

export const updatePersonSpheres = (id, values, sharing_token) => {
  return {
    type: UPDATE_PERSON_SPHERES,
    id: id,
    values: values,
    sharing_token: sharing_token,
  }
}

export const updatePersonSpheresSuccess = (person) => {
  return {
    type: UPDATE_PERSON_SPHERES_SUCCESS,
    person: person,
  }
}

export const updatePersonSpheresFail = (error) => {
  return {
    type: UPDATE_PERSON_SPHERES_FAIL,
    error: error,
  }
}

export const fetchPerson = (id) => {
  return {
    type: FETCH_PERSON,
    id: id,
  }
}

export const fetchPersonSuccess = (person) => {
  return {
    type: FETCH_PERSON_SUCCESS,
    person: person,
  }
}

export const fetchPersonFail = (error) => {
  return {
    type: FETCH_PERSON_FAIL,
    error: error,
  }
}

export const createContact = (values, image) => {
  return {
    type: CREATE_CONTACT,
    values: values,
    image: image,
  }
}

export const createContactSuccess = (person) => {
  return {
    type: CREATE_CONTACT_SUCCESS,
    person: person,
  }
}

export const createContactFail = (error) => {
  return {
    type: CREATE_CONTACT_FAIL,
    error: error,
  }
}

export const editContact = (id, values, sharing_token, related_people) => {
  return {
    type: EDIT_CONTACT,
    id: id,
    values: values,
    sharing_token: sharing_token,
    related_people: related_people,
  }
}

export const editContactSuccess = (person) => {
  return {
    type: EDIT_CONTACT_SUCCESS,
    person: person,
  }
}

export const editContactFail = (error) => {
  return {
    type: EDIT_CONTACT_FAIL,
    error: error,
  }
}

export const deleteContact = (id) => {
  return {
    type: DELETE_CONTACT,
    id: id,
  }
}

export const deleteContactSuccess = (id) => {
  return {
    type: DELETE_CONTACT_SUCCESS,
    personId: id,
  }
}

export const deleteContactFail = (error) => {
  return {
    type: DELETE_CONTACT_FAIL,
    error: error,
  }
}

export const updateContactImage = (id, image) => {
  return {
    type: UPDATE_CONTACT_IMAGE,
    id: id,
    image: image,
  }
}

export const updateContactImageSuccess = (person) => {
  return {
    type: UPDATE_CONTACT_IMAGE_SUCCESS,
    person: person,
  }
}

export const updateContactImageFail = (error) => {
  return {
    type: UPDATE_CONTACT_IMAGE_FAIL,
    error: error,
  }
}

export const deleteContacts = (selectedContacts) => {
  return {
    type: DELETE_CONTACTS,
    contact_ids: selectedContacts,
  }
}

export const deleteContactsSuccess = (people) => {
  return {
    type: DELETE_CONTACTS_SUCCESS,
    people: people,
  }
}

export const deleteContactsFail = (error) => {
  return {
    type: DELETE_CONTACTS_FAIL,
    error: error,
  }
}

export const editContacts = (contact_ids, values) => {
  return {
    type: EDIT_CONTACTS,
    contact_ids: contact_ids,
    values: values,
  }
}

export const editContactsSuccess = (people) => {
  return {
    type: EDIT_CONTACTS_SUCCESS,
    people: people,
  }
}

export const editContactsFail = (error) => {
  return {
    type: EDIT_CONTACTS_FAIL,
    error: error,
  }
}

export const mergeContacts = (
  selectedContacts,
  page,
  filters,
  query,
  by,
  order,
  main_contact_id
) => {
  return {
    type: MERGE_CONTACTS,
    contact_ids: selectedContacts,
    page: page || 1,
    filters: filters || null,
    q: query || null,
    by: by || "last_sent",
    order: order || "desc",
    main_contact_id: main_contact_id,
  }
}

export const mergeContactsSuccess = (merged, main) => {
  return {
    type: MERGE_CONTACTS_SUCCESS,
    merged: merged,
    main: main,
  }
}

export const mergeContactsFail = (error) => {
  return {
    type: MERGE_CONTACTS_FAIL,
    error: error,
  }
}

export const exportContacts = (all, spheres, contacts) => {
  return {
    type: EXPORT_CONTACTS,
    all: all,
    spheres: spheres,
    contacts: contacts,
  }
}

export const exportContactsSuccess = () => {
  return {
    type: EXPORT_CONTACTS_SUCCESS,
  }
}

export const exportContactsFail = (error) => {
  return {
    type: EXPORT_CONTACTS_FAIL,
    error: error,
  }
}

export const clearReduxPeopleState = () => {
  return {
    type: CLEAR_REDUX_PEOPLE_STATE,
  }
}

export const setInitialReduxPeopleState = () => {
  return {
    type: SET_INITIAL_REDUX_PEOPLE_STATE,
  }
}

export const unarchiveContact = (person_id) => {
  return {
    type: UNARCHIVE_CONTACT,
    contact_id: person_id,
  }
}

export const unarchiveContactSuccess = (person) => {
  return {
    type: UNARCHIVE_CONTACT_SUCCESS,
    person: person,
  }
}
export const unarchiveContactFail = (error) => {
  return {
    type: UNARCHIVE_CONTACT_FAIL,
    error: error,
  }
}

export const fetchSelectedPeople = (selected_ids) => {
  return {
    type: FETCH_SELECTED_PEOPLE,
    selected_ids: selected_ids,
  }
}

export const fetchSelectedPeopleSuccess = () => {
  return {
    type: FETCH_SELECTED_PEOPLE_SUCCESS,
  }
}

export const fetchSelectedPeopleFail = (error) => {
  return {
    type: FETCH_SELECTED_PEOPLE_FAIL,
    error: error,
  }
}

export const fetchDuplicatesCount = () => {
  return {
    type: FETCH_DUPLICATES_COUNT,
  }
}

export const fetchDuplicatesAction = (page) => {
  return {
    type: FETCH_DUPLICATES,
    page: page,
  }
}

export const fetchDuplicatesSuccess = (duplicates, total_entries) => {
  return {
    type: FETCH_DUPLICATES_SUCCESS,
    duplicates: duplicates,
    total_entries: total_entries,
  }
}

export const fetchDuplicatesFail = (error) => {
  return {
    type: FETCH_DUPLICATES_FAIL,
    error: error,
  }
}

export const approveDuplicate = (main, duplicate, page, participant_id = null) => {
  return {
    type: APPROVE_DUPLICATE,
    main: main,
    duplicate: duplicate,
    page: page,
    participant_id: participant_id,
  }
}

export const ignoreDuplicate = (main, duplicate, page, participant_id = null) => {
  return {
    type: IGNORE_DUPLICATE,
    main: main,
    duplicate: duplicate,
    page: page,
    participant_id: participant_id,
  }
}

export const approveDuplicateSuccess = (duplicate_id) => {
  return {
    type: APPROVE_DUPLICATE_SUCCESS,
    duplicate_id: duplicate_id,
  }
}

export const approveDuplicateFail = (error) => {
  return {
    type: APPROVE_DUPLICATE_FAIL,
    error: error,
  }
}

export const ignoreDuplicateSuccess = (duplicate_id) => {
  return {
    type: IGNORE_DUPLICATE_SUCCESS,
    duplicate_id: duplicate_id,
  }
}

export const ignoreDuplicateFail = (error) => {
  return {
    type: IGNORE_DUPLICATE_FAIL,
    error: error,
  }
}

export const fetchEnrichmentCount = () => {
  return {
    type: FETCH_ENRICHMENT_COUNT,
  }
}

export const fetchEnrichmentCountSuccess = (total_entries) => {
  return {
    type: FETCH_ENRICHMENT_COUNT_SUCCESS,
    total_entries: total_entries,
  }
}

export const fetchEnrichmentCountFail = () => {
  return {
    type: FETCH_ENRICHMENT_COUNT_FAIL,
  }
}

export const fetchEnrichments = (page) => {
  return {
    type: FETCH_ENRICHMENTS,
    page: page,
  }
}

export const fetchEnrichmentsSuccess = (enrichments, total_entries) => {
  return {
    type: FETCH_ENRICHMENTS_SUCCESS,
    enrichments: enrichments,
    total_entries: total_entries,
  }
}

export const fetchEnrichmentsFail = (error) => {
  return {
    type: FETCH_ENRICHMENTS_FAIL,
    error: error,
  }
}

export const approveEnrichment = (id) => {
  return {
    type: APPROVE_ENRICHMENT,
    id: id,
  }
}

export const ignoreEnrichment = (id) => {
  return {
    type: IGNORE_ENRICHMENT,
    id: id,
  }
}

export const approveEnrichmentSuccess = () => {
  return {
    type: APPROVE_ENRICHMENT_SUCCESS,
  }
}

export const approveEnrichmentFail = (error) => {
  return {
    type: APPROVE_ENRICHMENT_FAIL,
    error: error,
  }
}

export const ignoreEnrichmentSuccess = () => {
  return {
    type: IGNORE_ENRICHMENT_SUCCESS,
  }
}

export const ignoreEnrichmentFail = (error) => {
  return {
    type: IGNORE_ENRICHMENT_FAIL,
    error: error,
  }
}

export const showPeopleByIds = (ids) => {
  return {
    type: SHOW_PEOPLE_BY_IDS,
    people_ids: ids,
  }
}

export const showPeopleByIdsSuccess = () => {
  return {
    type: SHOW_PEOPLE_BY_IDS_SUCCESS,
  }
}

export const showPeopleByIdsFail = (error) => {
  return {
    type: SHOW_PEOPLE_BY_IDS_FAIL,
    error: error,
  }
}

export const updateEnabledColumns = (enabled_columns, old_enabled_columns) => {
  return {
    type: UPDATE_ENABLED_COLUMNS,
    enabled_columns: enabled_columns,
    old_enabled_columns: old_enabled_columns,
  }
}

export const updateEnabledColumnsSuccess = (enabled_columns) => {
  return {
    type: UPDATE_ENABLED_COLUMNS_SUCCESS,
    enabled_columns: enabled_columns,
  }
}

export const updateEnabledColumnsFail = (error) => {
  return {
    type: UPDATE_ENABLED_COLUMNS_FAIL,
    error: error,
  }
}

export const setPersonLoadingState = (fetching) => {
  return {
    type: SET_FETCHING_STATE,
    fetching: fetching,
  }
}

export const cloneTeamSharedPersonToMyDb = (
  person_id,
  token,
  mode = "edit",
  callback = null
) => {
  return {
    type: CLONE_TEAM_SHARED_PERSON_TO_MY_DB,
    person_id: person_id,
    token: token,
    mode: mode,
  }
}

export const cloneTeamSharedPersonToMyDbSuccess = (person, old_person_id) => {
  return {
    type: CLONE_TEAM_SHARED_PERSON_TO_MY_DB_SUCCESS,
    person: person,
    old_person_id: old_person_id,
  }
}

export const cloneTeamSharedPersonToMyDbFail = (error) => {
  return {
    type: CLONE_TEAM_SHARED_PERSON_TO_MY_DB_FAIL,
    error: error,
  }
}
