import React, { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Drawer, Tooltip } from "antd"
import "./ContactProfileDrawer.styles.scss"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import * as yup from "yup"
import {
  createContact,
  deleteContact,
  editContact,
  fetchPerson,
  fetchPersonsEvents,
  fetchPersonsInteractions,
  fetchPersonsNotes,
  fetchPersonsReminders,
  setPersonLoadingState,
  unarchiveContact,
  updateContactImage,
} from "../../../redux/People/People.actions"
import {
  setContactDrawerMode,
  setVisibleContactID,
  validationError,
} from "../../../redux/App/App.actions"
import { editNoteClear } from "../../../redux/Interactions/Interactions.actions"
import ContactImageInput from "./components/ContactImageInput/ContactImageInput.component"
import Icon from "@ant-design/icons"
import CancelIcon from "../../Icons/Cancel.icons"
import CaretLeftIcon from "../../Icons/CaretLeft.icons"
import PencilSimpleLineIcon from "../../Icons/PencilSimpleLine.icons"
import MiniDashboardForm from "../../UI/MiniDashboardForm/MiniDashboardForm.component"
import ContactDrawerNavSections from "./components/ContactDrawerNavSections/ContactDrawerNavSections.component"
import SectionDetails from "./components/././SectionDetails/SectionDetails.component"
import HeaderTitlesContactDrawer from "./components/HeaderTitlesContactDrawer/HeaderTitlesContactDrawer.component"
import SectionContact from "./components/SectionContact/SectionContact.component"
import SectionNotes from "./components/SectionNotes/SectionNotes.component"
import SectionActivity from "./components/SectionActivity/SectionActivity.component"
import SkeletonLoader from "./SkeletonLoader/SkeletonLoader.component"
import CheckCircleIcon from "../../Icons/CheckCircle.icons"
import TrashIcon from "../../Icons/Trash.icons"
import QuickContactButtons from "./QuickContactButton.component"
import SectionReminders from "./components/./SectionReminders/SectionReminders.component"
import checkUniqEmailAndPhoneNumber from "./ContactProfileDrawer.api"
import DuplicatedEmailAndPhoneNumbersModal from "./DuplicatedEmailAndPhoneNumbersModal/DuplicatedEmailAndPhoneNumbersModal.component"
import ErrorOverlay from "./ErrorOverlay/ErrorOverlay.component"

const ContactProfileDrawer = ({
  visible,
  contactId,
  contactPermalinkId,
  handleClose,
}) => {
  const tabs = [
    { name: "Details", width: 54, paddingLeft: 0 },
    {
      name: "Contact",
      width: 62,
      paddingLeft: 78,
    },
    { name: "Notes", width: 48, paddingLeft: 164 },
    {
      name: "Activity",
      width: 60,
      paddingLeft: 236,
    },
    { name: "Reminders", width: 72, paddingLeft: 320 },
  ]

  const people = useSelector((state) => state.PeopleState.people)
  const mode = useSelector((state) => state.AppState.contactDrawerMode)
  const activeTab = useSelector((state) => state.AppState.activeDrawerTab)
  const contactLoading = useSelector((state) => state.PeopleState.personLoading)

  const [contact, setContact] = useState(null)
  const [image, setImage] = useState(null)
  const [showDuplicatedModal, setShowDuplicatedModal] = useState(false)
  const [duplicatedEmails, setDuplicatedEmail] = useState([])
  const [duplicatedPhoneNumbers, setDuplicatedPhoneNumbers] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (activeTab !== "note" && activeTab) {
      dispatch(editNoteClear())
    }
    // eslint-disable-next-line
  }, [activeTab])

  useEffect(() => {
    if (people) {
      const person = people
        .filter(
          (p) => +p.id === +contactPermalinkId || p.slug === contactPermalinkId
        )
        .sort((a, b) => Object.keys(b).length - Object.keys(a).length)[0]

      if (!contactId && person) {
        dispatch(setVisibleContactID(person.id, contactPermalinkId))
      }

      if (person) {
        setContact(person)
      } else if (contactPermalinkId) {
        dispatch(fetchPerson(contactPermalinkId))
      }
    }
    // eslint-disable-next-line
  }, [people])

  useEffect(() => {
    if (!contactId) {
      setContact(null)
      setImage(null)
    }
  }, [mode, contactId])

  useEffect(() => {
    if (contactPermalinkId) {
      dispatch(fetchPerson(contactPermalinkId))
    }
    // eslint-disable-next-line
  }, [contactPermalinkId])

  const formatRequest = (values) => {
    return {
      first_name: values.first_name,
      last_name: values.last_name,
      full_name: [values.first_name, values.last_name].join(" "),
      one_liner: values.one_liner,
      location: values.location,
      spheres: values.spheres,
      topics: values.topics,
      expertises: values.expertises,
      companies: values.companies,
      profile_fields: values.profile_fields,
      email_addresses: values.email_addresses,
      phone_numbers: values.phone_numbers,
      addresses: values.addresses,
      links: values.links,
      birthday_year: values.birthday_year,
      birthday: values.birthday,
      special_dates: values.special_dates,
      connexions: values.connexions,
      sources: values.sources,
    }
  }

  const uniqueValues = (emails) => {
    const emailSet = new Set()
    for (const email of emails) {
      if (emailSet.has(email.value)) {
        return false
      }
      emailSet.add(email.value)
    }
    return true
  }

  const schema = yup.object().shape({
    first_name: yup
      .string()
      .nullable()
      .when(["email_addresses", "phone_numbers"], {
        is: (a, b) => a?.length === 0 && b?.length === 0,
        then: yup.string().required("Please fill out the first name"),
      }),
    last_name: yup
      .string()
      .nullable()
      .when(["email_addresses", "phone_numbers"], {
        is: (a, b) => a?.length === 0 && b?.length === 0,
        then: yup.string().required("Please fill out the last name"),
      }),
    one_liner: yup.string().nullable(),
    location: yup.string().nullable(),
    spheres: yup
      .array()
      .of(
        yup.object().shape({
          title: yup.string().required(),
        })
      )
      .min(1, "Every relationship needs to be in one sphere"),
    topics: yup.array().of(yup.object()),
    expertises: yup.array().of(yup.object()),
    companies: yup.array().of(yup.object()),
    connexions: yup.array().of(yup.object()),
    sources: yup.array().of(yup.object()),
    profile_fields: yup.array().of(yup.object()),
    email_addresses: yup.array().of(
      yup.object().shape({
        value: yup.string().email("Invalid email"),
      })
    ),
    // .test("unique", "Email must be unique", uniqueValues),
    phone_numbers: yup.array().of(
      yup.object().shape({
        value: yup.string().min(4),
        name: yup.string(),
      })
    ),
    // .test("unique", "Phone number must be unique", uniqueValues),
    addresses: yup.array().of(
      yup.object().shape({
        value: yup.string().required("You can't add empty address"),
        name: yup.string(),
      })
    ),
    links: yup.array().of(
      yup.object().shape({
        value: yup.string().required("Url is required"),
      })
    ),
    birthday_year: yup.boolean(),
    birthday: yup
      .date()
      .min("1900-01-01", `Birthday should not be in the far past`)
      .max("2100-01-01", `Birthday should not be in the far future`)
      .nullable(),
    special_dates: yup.array().of(
      yup.object().shape({
        date: yup.date().typeError("Date is required").required("Date is required"),
        name: yup.string().required("You can't add empty special date"),
        year: yup.boolean().nullable(),
        date_type: yup.string(),
      })
    ),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      one_liner: "",
      location: "",
      spheres: [],
      topics: [],
      expertises: [],
      companies: [],
      profile_fields: [],
      email_addresses: [],
      phone_numbers: [],
      addresses: [],
      links: [],
      birthday: "",
      birthday_year: false,
      special_dates: [],
      connexions: [],
      sources: [],
    },
    validationSchema: schema,
    onSubmit: (values) => {
      handleCheckEmailsAndPhoneNumbers(values)
    },
  })

  const handleSubmitForm = (values) => {
    if (contactId) {
      dispatch(
        editContact(contactId, formatRequest(values), null, contact.related_people)
      )
    } else {
      dispatch(createContact(formatRequest(values), image))
      formik.resetForm()
      handleClose()
    }
  }

  const handleCheckEmailsAndPhoneNumbers = (values) => {
    dispatch(setPersonLoadingState(true))
    checkUniqEmailAndPhoneNumber(contactId, values, (data) => {
      if (
        (data.duplicated_emails && data.duplicated_emails.length > 0) ||
        (data.duplicated_phone_numbers && data.duplicated_phone_numbers.length > 0)
      ) {
        dispatch(setPersonLoadingState(false))
        setShowDuplicatedModal(true)
        setDuplicatedEmail(data.duplicated_emails)
        setDuplicatedPhoneNumbers(data.duplicated_phone_numbers)
      } else {
        dispatch(setPersonLoadingState(false))
        handleSubmitForm(values)
      }
    })
  }

  const setFormikValues = (contact) => {
    formik.setValues({
      first_name: contact.first_name,
      last_name: contact.last_name,
      one_liner: contact.one_liner,
      location: contact.location,
      spheres: contact.spheres,
      topics: contact.topics,
      expertises: contact.expertises,
      companies: contact.companies,
      email_addresses: contact.email_addresses,
      phone_numbers: contact.phone_numbers,
      profile_fields: contact.profile_fields,
      addresses: contact.addresses,
      links: contact.links,
      birthday: contact.birthday ? contact.birthday : null,
      birthday_year: contact.birthday_year ? contact.birthday_year : false,
      special_dates: contact.special_dates,
      connexions: contact.connexions,
      sources: contact.sources,
    })
  }

  useEffect(() => {
    if (contact) {
      setFormikValues(contact)
    }
    // eslint-disable-next-line
  }, [contact, mode])

  const handleClickArchive = () => {
    handleClose()
    dispatch(deleteContact(contact.id))
  }

  const handleClickSave = async () => {
    await formik.setFieldValue(
      "email_addresses",
      formik.values.email_addresses.filter((e) => e.value !== "")
    )

    await formik.setFieldValue(
      "phone_numbers",
      formik.values.phone_numbers.filter((e) => e.value !== "")
    )

    await formik.setFieldValue(
      "addresses",
      formik.values.addresses.filter((e) => !!e.value && e.value !== "")
    )

    await formik.setFieldValue(
      "links",
      formik.values.links.filter((e) => !!e.value && e.value !== "")
    )

    await formik.setFieldValue(
      "profile_fields",
      formik.values.profile_fields.filter((e) => !!e.value && e.value !== "")
    )

    formik.submitForm().then(() => {
      if (!formik.isValid && !formik.isValidating && !formik.isSubmitting) {
        dispatch(validationError(formik.errors))
      }
    })
  }
  const handleClickCancel = () => {
    formik.resetForm()
    handleClose()
  }
  const handleClickEdit = () => {
    dispatch(setContactDrawerMode("editing"))
  }

  return (
    <Drawer
      className={"ContactProfileDrawerWrapper"}
      placement="right"
      mask={false}
      closable={false}
      destroyOnClose={true}
      afterVisibleChange={(visible) => {
        if (!visible) {
          setContact(null)
          formik.resetForm()
          handleClose()
        }
      }}
      visible={visible}
      getContainer={true}
      width="430px"
    >
      <DuplicatedEmailAndPhoneNumbersModal
        currentContact={
          contact?.id
            ? {
                person_id: contact?.id,
                person_full_name: contact?.full_name,
              }
            : null
        }
        mergeAvailable={!!contact?.id}
        visible={showDuplicatedModal}
        duplicatedEmails={duplicatedEmails}
        duplicatedPhoneNumbers={duplicatedPhoneNumbers}
        onCancel={() => setShowDuplicatedModal(false)}
      />
      <div
        className={`ContactProfileDrawer ${
          mode === "editing" ? "ContactDrawerFade" : ""
        }`}
      >
        {(contact && contact.id && !contactLoading) ||
        (!contactId && mode === "editing") ? (
          <>
            <div className="ContactProfileDrawer_Header">
              <CSSTransition in={!!contact} timeout={1000} classNames="fade" appear>
                <div className="ContactProfileDrawer_Header_Buttons">
                  {mode === "editing" ? (
                    <>
                      <Tooltip placement={"bottom"} title="Save">
                        <div
                          className={"ContactProfileDrawer_Header_Buttons_Save"}
                          onClick={handleClickSave}
                        >
                          <Icon component={CheckCircleIcon} />
                        </div>
                      </Tooltip>
                      {contact && (
                        <Tooltip placement={"bottom"} title="Archive">
                          <div
                            className={"ContactProfileDrawer_Header_Buttons_Archive"}
                            onClick={handleClickArchive}
                          >
                            <Icon component={TrashIcon} />
                          </div>
                        </Tooltip>
                      )}
                      <Tooltip placement={"bottom"} title="Cancel">
                        <div
                          className={"ContactProfileDrawer_Header_Buttons_Cancel"}
                          onClick={handleClickCancel}
                        >
                          <Icon component={CancelIcon} />
                        </div>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Back" placement="left">
                        <Icon component={CaretLeftIcon} onClick={handleClose} />
                      </Tooltip>
                      {contact.status === "archived" && (
                        <div className="ContactProfileDrawer_Header_Archive">
                          <p>Archived</p>{" "}
                          <span
                            onClick={() => dispatch(unarchiveContact(contact.id))}
                            className="ContactProfileDrawer_Header_Archive_Unarchive"
                          >
                            Unarchive?
                          </span>
                        </div>
                      )}
                      <Tooltip title="Edit" placement="left">
                        <Icon
                          component={PencilSimpleLineIcon}
                          onClick={handleClickEdit}
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
              </CSSTransition>
              <ContactImageInput
                image={image}
                setImage={setImage}
                contact={contact}
                disabled={mode !== "editing"}
                handleUpdateImage={(id, image) =>
                  dispatch(updateContactImage(id, image))
                }
              />
              {mode !== "create" && (
                <CSSTransition
                  in={!!contact}
                  timeout={1200}
                  classNames="fade"
                  appear
                >
                  <>
                    <QuickContactButtons contact={contact} />
                    <MiniDashboardForm
                      contacts={contact ? [contact] : []}
                      defaultActiveTab={activeTab}
                    />
                  </>
                </CSSTransition>
              )}

              <CSSTransition in={!!contact} timeout={1500} classNames="fade" appear>
                <>
                  <HeaderTitlesContactDrawer
                    formik={formik}
                    contact={contact}
                    isEditing={mode === "editing"}
                  />
                  <ContactDrawerNavSections
                    containerId={"ContactDrawerSections"}
                    tabs={tabs}
                  />
                </>
              </CSSTransition>
            </div>
            <CSSTransition in={!!contact} timeout={1800} classNames="fade" appear>
              <div
                className={`ContactProfileDrawer_Sections ${
                  contact && contact.status === "archived"
                    ? "ContactProfileDrawer_Sections_Archived"
                    : ""
                }`}
                id="ContactDrawerSections"
              >
                <SectionDetails
                  formik={formik}
                  mode={mode}
                  contact={contact}
                  isEditing={mode === "editing"}
                />
                <SectionContact formik={formik} contact={contact} mode={mode} />
                <SectionNotes
                  contact={{
                    id: contact?.id,
                    full_name:
                      contact?.first_name ||
                      contact?.last_name ||
                      (contact?.email_addresses &&
                        contact?.email_addresses[0]?.value),
                    notes: contact?.notes,
                    notes_total_entries: contact?.notes_total_entries,
                  }}
                  loadMore={(contactId, page) =>
                    dispatch(fetchPersonsNotes(contactId, page))
                  }
                />
                <SectionActivity
                  contact={{
                    id: contact?.id,
                    interactions: contact?.interactions,
                    interactions_total_entries: contact?.interactions_total_entries,
                    events: contact?.events,
                    events_total_entries: contact?.events_total_entries,
                    canonical_participants: contact?.canonical_participants,
                  }}
                  loadMoreInteractions={(contactId, page, pageToken) =>
                    dispatch(fetchPersonsInteractions(contactId))
                  }
                  loadMoreEvents={(contactId, page) =>
                    dispatch(fetchPersonsEvents(contactId, page))
                  }
                />
                <SectionReminders
                  contact={{
                    id: contact?.id,
                    reminders: contact?.reminders,
                    reminders_total_entries: contact?.reminders_total_entries,
                  }}
                  loadMore={(contactId, page) =>
                    dispatch(fetchPersonsReminders(contactId, page))
                  }
                />
              </div>
            </CSSTransition>
          </>
        ) : contactLoading ? null : (
          <ErrorOverlay handleClose={handleClose} />
        )}
        <SkeletonLoader active={contactLoading} />
      </div>
    </Drawer>
  )
}

export default ContactProfileDrawer
